import React, { useCallback } from 'react'
import styled from 'styled-components'
import { graphql, Link, withPrefix } from 'gatsby'
import { useSpring, config, animated } from '@react-spring/web'
import {
    Container,
    Layout,
    LocaleConfig,
    SEO,
    usePageSections,
    useTranslations,
} from '../modules/core'

export interface NotFoundPageData {
    allMarkdownRemark: {
        edges: Array<{
            node: {
                frontmatter: {
                    section: 'main'
                    title: string
                    locale: LocaleConfig['locale']
                }
                html: string
            }
        }>
    }
}

export const query = graphql`
    query NotFoundPageQuery($locale: String) {
        allMarkdownRemark(
            filter: {
                frontmatter: {
                    page: { eq: "NotFoundPage" }
                    locale: { eq: $locale }
                }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        section
                        title
                    }
                    html
                }
            }
        }
    }
`

interface NotFoundPageProps {
    path: string
    pageContext: {
        localeConfig: LocaleConfig
    }
    data: NotFoundPageData
}

const calc = (x: number, y: number) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
]
const astronaut = (x: number, y: number) =>
    `translate(${50 + x * 0.03}%, ${
        50 + y * 0.03 - Math.sqrt(2048 - x * 0.03 * x * 0.03)
    }%)`
const world = (x: number) => `translate(${50 + x * 0.002}%, -50%)`

const NotFoundPage = ({ path, pageContext, data }: NotFoundPageProps) => {
    const [{ xy }, set] = useSpring(() => ({
        xy: [0, 0],
        config: config.molasses,
    }))
    const sections = usePageSections(data)
    const { translate } = useTranslations(pageContext.localeConfig.locale)

    const handleMouseMove = useCallback(
        ({ clientX, clientY }) => {
            set({ xy: calc(clientX, clientY) })
        },
        [set]
    )

    return (
        <Layout
            locale={pageContext.localeConfig}
            path={path}
            hasContactSection={false}
        >
            <SEO title="404: Not found" />
            <PageWrapper onMouseMove={handleMouseMove}>
                <Container>
                    <ContentWrapper>
                        <Content>
                            <Logo>404</Logo>
                            <Title>{sections.main.title}</Title>
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: sections.main.content,
                                }}
                            />
                            <HomeButton to="/">
                                {translate('page_home')}
                            </HomeButton>
                        </Content>
                    </ContentWrapper>
                </Container>
                <Illustration>
                    <Stars />
                    <World
                        as={animated.div}
                        style={{ transform: xy.to(world) }}
                    />
                    <Astronaut
                        as={animated.div}
                        style={{ transform: xy.to(astronaut) }}
                    />
                </Illustration>
            </PageWrapper>
        </Layout>
    )
}

export default NotFoundPage

const PageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 383px);
    background-color: ${({ theme }) => theme.colors.accent};
    padding: 184px 0;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        padding: 32px 0;
    }
`

const Illustration = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 383px);
    z-index: 1;
`

const Stars = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 723px;
    height: 508px;
    background-image: url('${withPrefix('/images/stars.svg')}');
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(-50%, -50%);
    z-index: 1;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 289px;
        height: 203px;
        background-size: contain;
    }
`

const World = styled.div`
    position: absolute;
    top: calc(50% + 40px);
    left: calc(50% + 60px);
    width: 230px;
    height: 230px;
    background-image: url('${withPrefix('/images/world.svg')}');
    background-repeat: no-repeat;
    background-position: center;
    will-change: transform;
    z-index: 2;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        top: calc(50% + 47px);
        left: calc(50% + 18px);
        width: 92px;
        height: 92px;
        background-size: contain;
    }
`

const Astronaut = styled.div`
    position: absolute;
    top: calc(50% - 240px);
    left: 50%;
    width: 142px;
    height: 171px;
    background-image: url('${withPrefix('/images/astronaut.svg')}');
    background-repeat: no-repeat;
    background-position: center;
    will-change: transform;
    z-index: 3;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        top: calc(50% - 95px);
        left: calc(50% - 40px);
        width: 57px;
        height: 68px;
        background-size: contain;
    }
`

const ContentWrapper = styled.div`
    position: relative;
    padding: 0 80px;
    z-index: 2;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        padding: 0;
    }
`

const Content = styled.div``

const Logo = styled.div`
    font-size: 100px;
    line-height: 100px;
    margin-bottom: 40px;
    ${({ theme }) => theme.typography_presets.bold};
`

const Title = styled.h1`
    font-size: 20px;
    line-height: 30px;
    white-space: pre-wrap;
    margin: 0 0 16px 0;
    ${({ theme }) => theme.typography_presets.bold};
`

const Description = styled.div`
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 32px;
`

const HomeButton = styled(Link)`
    height: 62px;
    width: 192px;
    background-color: ${({ theme }) => theme.colors.invertedBackground};
    color: ${({ theme }) => theme.colors.invertedText};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 31px;
    border: 2px solid ${({ theme }) => theme.colors.invertedBackground};
    cursor: pointer;
    text-decoration: none;
    ${({ theme }) => theme.typography_presets.bold};

    &:hover {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.text};
    }
`
